@import "~@/styles/variables";





















.back-link {
  display: inline-block;
  margin-left: -0.5em;
  font-style: italic;
}
