@import "~@/styles/variables";


































.signup-physician-card {
  border: 1px solid #ddd;
  border-radius: $border-radius;
  padding: 1em;

  .content {
    display: flex;

    .info {
      flex-grow: 1;

      .location {
        color: $pp-blue-grey;
      }
    }
  }
}
