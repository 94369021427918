@import "~@/styles/variables";





















































































































































































.search-icon ::v-deep {
  path {
    fill: currentColor;
  }
}
