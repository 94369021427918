@import "~@/styles/variables";























































































































































































.search-icon ::v-deep {
  path {
    fill: currentColor;
  }
}
.physicians_search__styled-link {
  display: block;
  float: right;
  margin-top: 4px;

  color: $pp-blue;
  font-family: $flama;
  font-size: $f-size-rg;
  font-weight: $f-weight-medium;
  text-decoration: underline;

  &:focus,
  &:hover {
    cursor: pointer;
    color: darken($pp-blue, 5%);
  }
}
