@import "~@/styles/variables";















.input-place {
  background-image: $pp-location-icon-url;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 12px 12px;
  padding-left: 2.5rem;
}
