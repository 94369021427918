@import "~@/styles/variables";









































.add-rx-med-name {
  font-family: $flama;
  margin-bottom: 0;
}
